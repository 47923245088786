import cover1 from '../photos/cover1.png'
import { Link } from 'react-router-dom'

export default function Topnav() {
    return (
        <>
            <div className="bg-zinc-700 p-16 z-10 overflow-visible w-full relative mb-24">

                <div className="text-center">
                    <div className=" text-violet-200 max-lg:text-4xl lg:text-6xl absolute left-16 bottom-2 border-red-400">metsucat</div>
                    {/* <div>Come look and buy shit thanks</div> */}
                </div>
                {/* <div className='absolute flex top-0 -z-50'>
                <img src={cover1} className='w-[140px]' />
                <img src={cover1} className='w-[140px]' />
                <img src={cover1} className='w-[140px]' />
                <img src={cover1} className='w-[140px]' />
                </div> */}

                <div className='absolute max-lg:right-4 lg:right-[50%] lg:translate-x-1/2 text-white flex gap-8'>

                    <Link to='/studies'>STUDIES</ Link>
                    <Link to='/'>HOMEPAGE</ Link>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                        </svg>
                    </div>
                </div>

            </div>
        </>
    )
}