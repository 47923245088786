import pic from '../photos/studies/9c62219ed84cba3963a83c07dfefde5a.png'
import pic1 from '../photos/studies/438cca8df68e1902ef52b8e339d3eb80.jpg'
import pic2 from '../photos/studies/a33ee89bbcb9836d793dadcb2d1f49da.png'
import pic3 from '../photos/studies/aca26aae25652fccbe692d6e4b0f8f3e.jpg'
import pic4 from '../photos/studies/b_3.jpg'
import pic5 from '../photos/studies/IMG_1034.jpg'
import pic7 from '../photos/studies/IMG_1586-0.jpg'
import pic8 from '../photos/studies/IMG_1806.jpg'
import pic9 from '../photos/studies/IMG_2149.jpg'
import pic10 from '../photos/studies/IMG_2895.jpg'
import pic11 from '../photos/studies/IMG_3037.jpg'
import pic12 from '../photos/studies/IMG_5734.jpg'
import pic13 from '../photos/studies/IMG_6615.jpg'
import pic14 from '../photos/studies/IMG_7299-0-0png.png'
import pic15 from '../photos/studies/IMG_7529.jpg'
import pic16 from '../photos/studies/IMG_8637.jpg'
import pic17 from '../photos/studies/IMG_9050.jpg'
import pic18 from '../photos/studies/IMG_9183.jpg'
import pic19 from '../photos/studies/IMG_9188.jpg'
import picumbrella from '../photos/studies/picumbrella.png'
import firstoil from '../photos/studies/firstoil.jpg'
import nana1forest from '../photos/studies/nana1forest.png'
import headblanket from '../photos/studies/headblanket.jpg'
import prima from '../photos/studies/prima.jpg'
import blueflower from '../photos/studies/blueflower.jpg'
import dragred from '../photos/studies/dragred.png'
import dragblue from '../photos/studies/dragblue.png'

const images = [
    //2024
    {src:pic11, description:"2023-2024 oil, raw canvas", size:`"29" x 41"`},
    {src:pic13, description:"2024 oil, raw canvas", size:`"29" x 41"`},
    //2023
    {src:pic19, description:"? - 2023 oil, canvas", size:`"30" x 30"`},
    //2022
    {src:pic1, description:"2022? oil, linen", size:`20" x 36"`},
    // {src:pic5, description:"20??, canvas", size:`16" x 20"`},
    // {src:pic10, description:"2021-2022, oil on canvas", size:`16" x 20"`},
    {src:pic14, description:"2022 oil, canvas", size:`30" x 30"`},
    {src:prima, description:"Jan 2022 oil, canvas board", size:`11" x 14"`},
    {src:pic12, description:"Jan - Mar 2022 oil, canvas", size:`16" x 20"`},
    //2021
    {src:pic4, description:"Jun - Aug 2021, oil, canvas", size:`16" x 20"`},
    {src:nana1forest, description:"Feb - Apr 2021 oil, canvas", size:`16" x 20"`, comment:"started using naples yellow and cobalt violet"},
    //2020
    // {src:pic9, description:"2020 acrylic on canvas", size:`16" x 20"`},
    {src:picumbrella, description:"July 2020 acrylic, canvas", size:`16" x 20"`},
    {src:firstoil, description:"Sep 2020 oil, canvas", size:`16" x 20"`},
    //2019
    // {src:blueflower, description:"2019 acrylic on canvas board", size:`11" x 14"`},
    {src:headblanket, description:"2019 acrylic, canvas board", size:`11" x 14"`},
    //2018
    // {src:dragred, description:"2018 acrylic on canvas", size:`12" x 16""`},
    {src:dragblue, description:"2018 acrylic, canvas", size:`12" x 16""`},


]

export default function Studies() {
    return (
        <>

            <div>

                <div className='flex flex-wrap gap-12 justify-center lg:mx-36'>
                    {images && images.map((image, index) => (
                        <div key={index} className='group relative'>
                            <img className='w-[340px] h-[340px] max-md:w-[200px] max-md:h-[200px] object-cover group-hover:object-contain group-hover:bg-black' src={image.src} />
                            <div className='hidden select-none group-hover:block group-hover:bg-black group-hover:bg-opacity-40 group-hover:text-white absolute bottom-1 p-1 left-[50%] -translate-x-1/2'>
                                <div>{image.description}</div>
                                <div>{image.size}</div>
                            </div>

                        </div>
                    ))}
                </div>

                <div className='pb-20 pt-20 px-20'>
                    <div className="text-3xl">Studies</div>

                    <div>Some works/studies I made. Not for sale because they have either changed compositions, not happy with them, or are works from other artists. I just like recreating images I see on twitter.</div>
                </div>

            </div>

        </>
    )
}