import photo1 from '../photos/IMG_7505.jpg'
import photo2 from '../photos/IMG_7506.jpg'
import photo3 from '../photos/IMG_7507.jpg'
import photo4 from '../photos/IMG_7508.jpg'
import photo5 from '../photos/IMG_7509.jpg'
import photo6 from '../photos/IMG_7510.jpg'

export default function Homepage() {
    return (
        <>
            <div className="px-8 py-8">

                <div className='grid grid-cols-3 gap-24 flex-wrap [&>div]:w-[200px] [&>div]:object-cover'>
                    <div>
                        <img src={photo1} />
                        <div>
                            <div>test</div>
                            <div>11" x 14"</div>
                            <div>$300</div>
                        </div>
                    </div>
                    <div>
                        <img src={photo2} />
                        <div>
                            <div>test</div>
                            <div>16" x 20"</div>
                            <div>$300</div>
                        </div>
                    </div>                    
                    <div>
                        <img src={photo3} />
                        <div>
                            <div>test</div>
                            <div>16" x 20"</div>
                            <div>$300</div>
                        </div>
                    </div>                    
                    <div>
                        <img src={photo4} />
                        <div>
                            <div>test</div>
                            <div>16" x 20"</div>
                            <div>$300</div>
                        </div>
                    </div>                    
                    {/* <div>
                        <img src={photo5} />
                        <div>
                            <div>test</div>
                            <div>16" x 20"</div>
                            <div>$300</div>
                        </div>
                    </div>                     */}
                    <div>
                        <img src={photo6} />
                        <div>
                            <div>test</div>
                            <div>30" x 30"</div>
                            <div>$300</div>
                        </div>
                    </div>


                </div>

                {/* <div className="italic text-xl">who am i?</div>
                <div>I enjoy oil painting. I started off with acrylics for a couple years and made the switch
                    to oils because acrylics look nice when wet but when they dry the value steps down. Oil has a ton more
                    pigments like lead antimonate and mercuric sulfide which is apparently too dangerous to be sold in Europe but we still think it's OK
                    to overprescribe pain killers and pay taxes and get up in the morning and support overseas child labor.
                </div>
                
                <div className="italic text-xl">why do your paintings <span className="text-red-500">suck. </span><span className="text-cyan-400">?</span></div>
                <div>
                    Idk man
                </div> */}

                <div className='py-12'>
                    <div className=' font-extrabold tracking-wide text-red-500'>ABOUT ME</div>
                    <div>I mainly paint studies cause I don't have any good ideas. I started painting in 2018 after I saw a youtube video on Kandinsky.</div>
                </div>

                {/* <div className='py-12'>
                    <div className=' font-extrabold tracking-wide text-red-500'><span className='text-green-500'>MATERIALS</span> I USE</div>
                    <div>Paints: lead based, I avoid cadmiums and never touch titanium white. <span className='text-red-800 font-bold'>Why?</span> Quicker drying time plus they <span className='text-yellow-300 bg-black shadow-lg font-extrabold '>sparkle</span> </div>
                    <div>Usually paint on canvas. Linen if I can afford. </div>
                </div> */}

            </div>
        </>
    )
}