import './App.css';
import Footer from './components/Footer.js';
import Homepage from './components/Homepage.js';
import Studies from './components/Studies.js';
import Topnav from './components/Topnav.js';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <div className='bg-zinc-10'>
        <Topnav />
        <Routes>

          <Route path='/' element={<Homepage />} />
          <Route path='studies' element={<Studies />} />

        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
