import ec2 from '../photos/icons/AWS_ec2.png'
import k8s from '../photos/icons/k8s.png'

export default function Footer() {
  return (
    <>
      <div className="w-full p-12 bg-zinc-100 text-zinc-400">

        <div className='text-center pb-6'>© metsucat</div>
        {/* <div className="flex items-center gap-1">Powered by <span className="text-blue-700 font-extrabold">AWS</span> <span className="font-extrabold text-green-600">ELASTIC CLOUD COMPUTE</span> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" text-orange-600" viewBox="0 0 16 16">
  <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1"/>
Z</svg> */}
        <div className="flex flex-col justify-center items-center">
          <p className='text-md'>Powered by</p>
          <div className='flex gap-2 items-center [&>a]:w-[35px]'>
            <a href='https://aws.amazon.com/ec2/' target='_blank'><img src={ec2} alt='AWS EC2'/></a>
            <a href='https://kubernetes.io/' target='_blank'><img src={k8s} alt='kubernetes'/></a>

          </div>
        </div>


      </div>
    </>
  )
}